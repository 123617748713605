import {baseURL} from "@/api/erpApi";

export default {
    mtype: "GET",
    datatype: "json",
    height: "auto",
    autowidth: true,
    rowList: [10, 20, 50, 100],
    rowNum: 10,
    page: 1,
    loadtext: '<p>Cargando...</p>',
    hoverrows: false,
    refresh: true,
    multiselect: true,
    url: baseURL + '/items',
    colNames: [
        "Código",
        "Nombre",
        "Estado",
        '',
        '',
    ],
    colModel: [
        {name: "code", width: 55},
        {name: "name", width: 55},
        {name: "statusLabel", width: 40, align: 'center'},
        {name: "options", width: 55, align: 'center'},
        {name: "hiddenOptions", hidden: true}
    ],
    sortname: 'createdAt',
    sortorder: "desc",
    viewrecords: true,
    gridview: true
};
